
import { mapGetters } from 'vuex'
import bannerData from "@/data/banner.json";
import optionsData from '@/data/options.json';

export default {
  data() {
    return { 
      banner: {},
      options: optionsData
    };
  },
  async fetch() {
    this.banner = await bannerData;
  },
  computed: {
    bgColour(){
      return this.region === 'en-us' ? this.banner.banner_background_color_us : this.banner.banner_background_color
    },
    buttonColour(){
      return this.region === 'en-us' ? this.banner.banner_button_primary_color_us : this.banner.banner_button_primary_color
    },
    link(){
      const bf = this.banner.bf_slider === 'bf_slider' ? 'special-offers' : 'special-offers'
      const bf_us = this.banner.bf_slider_us === 'bf_slider' ? 'special-offers' : 'special-offers'
      const bf_link = this.region === 'en-us' ? bf_us : bf
      return this.region === 'en-us' ? '/en-us/'+bf_link+'/' : '/'+bf_link+'/'
    },
    ...mapGetters({
      // region: 'user/region',
      detectedRegion: 'geolocation/region',
      userRegion: 'user/region',
    }),
    region(){
      return this.userRegion === 'default' ? this.detectedRegion : this.userRegion
    }
  },
};
