// source: https://github.com/andymantell/node-wpautop
  /* eslint-disable */
const wpfix =  (pee) => {

if (pee){

    if ( pee.trim() === '' ) {
        return '';
    }

    pee = pee + "\n"; // just to make things a little easier, pad the end
    pee = pee.replace(/<br \/>\s*<br \/>/, "\n\n");
   // pee = pee.replace(/<\/span>\n\n/gmi, "</span></p>");
    // Space things out a little
    var allblocks = '(?:|div|dl|dd|dt|ul|ol)';

    pee = pee.replace( new RegExp('(<' + allblocks + '[^>]*>)', 'gmi'), "\n$1");
    pee = pee.replace( new RegExp('(</' + allblocks + '>)', 'gmi'), "$1\n\n");
    pee = pee.replace( /\r\n|\r/, "\n" ) // cross-platform newlines
    pee = pee.replace(/\n\n+/gmi, "\n\n"); // take care of duplicates
    pee = pee.replace(/\]\r\n\[+/gmi, "][");

   //pee = pee.replace(/\r\n+/gmi, "<br />");
    // make paragraphs, including one at the end
    var pees = pee.split(/\n\s*\n/);
    pee = '';
    pees.forEach(function(tinkle) {
        pee += '<p>' + tinkle.replace( /^\s+|\s+$/g, '' ) + "</p>\n";
    });
    //</span>\n\n
    pee = pee.replace(/<p>\s*<\/p>/gmi, ''); // under certain strange conditions it could create a P of entirely whitespace
    pee = pee.replace(/<p>([^<]+)<\/(div|address|form)>/gmi, "<p>$1</p></$2>");
    pee = pee.replace(new RegExp('<p>\s*(</?' + allblocks + '[^>]*>)\s*</p>', 'gmi'), "$1", pee); // don't pee all over a tag
    pee = pee.replace(/<p>(<li.+?)<\/p>/gmi, "$1"); // problem with nested lists
    pee = pee.replace(/<p><blockquote([^>]*)>/gmi, "<blockquote$1><p>");
    pee = pee.replace(/<\/blockquote><\/p>/gmi, '</p></blockquote>');
    pee = pee.replace(new RegExp('<p>\s*(</?' + allblocks + '[^>]*>)', 'gmi'), "$1");
    pee = pee.replace(new RegExp('(</?' + allblocks + '[^>]*>)\s*</p>', 'gmi'), "$1");

    // get rid of any paragraphs before a shortcode
    //pee = pee.replace(/<p (\w+="(?:.*?)")>/gmi,"<p>")
    pee=pee.replace(/<p>\[/gmi,"[")
    pee=pee.replace(/<p>A/gmi,"A")
    pee=pee.replace(/\]<\/p>/gmi,"]")

    return  pee;
}else {return}
}

export default wpfix
