export default ({ store, route, payload,redirect },inject) => {
    if (payload) {
        inject('page', payload)
        store.commit('pages/setPage',payload)
       }

 if(store.getters['user/region']==='en-us'){
    console.log('US user',route.path,route.path==='/courses/')
    if(route.path==='/courses/' && process.client){
     window.onNuxtReady(() => { window.$nuxt.$router.push('/en-us/courses/') })
    }
}
  }
