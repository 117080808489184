
  export default {
    props: {
      country: {
        type: String,
      },

  jobs_url:{
    type: String,
        default: '',
  },
      thumbnail: {
        type: String,
        default: '',
      },
      image: {
        type: String,
        default: '',
      },
      title: {
        type: String,
      },
       date: {
        type: String,
        default: ''
      },
      text: {
        type: String,
      },
      uri: {
        type: String,
        default: '',
      },
      guide_url: {
        type: String,
        default: '',
      },
      first: {
        type: String,
      },
      last: {
        type: String,
      },
      featured:{
        type:Boolean
      },
      jobListingType:{
        type: String
      }
    },
    computed: {
    backgroundStyles() {
      if(this.$props.thumbnail !== ''){
        const imgUrl = this.$img(this.$props.thumbnail, { width: '350px',format:'webp',quality:50,fit:'cover' });
        return {
          backgroundImage: `url(${imgUrl})`
        }
      } else if(this.$props.image !== ''){
        const imgUrl = this.$img(this.$options.filters.cdn(this.$props.image), { width: '350px',format:'webp',quality:50,fit:'cover' });
        return {
          backgroundImage: `url(${imgUrl})`
        } 
      }
      else{
      return ''
    }
    }
  }
  }
  