import * as rudderanalytics from "rudder-sdk-js";
export default (context, inject) => {
    window.rudderanalytics = rudderanalytics
    rudderanalytics.ready(() => {
        console.log("We are all set!");
        // Check anonID
        const id = context.$rs.getAnonymousId();
        console.log('ANONID',id)
        context.$rs.page(null, null, {
            integrations: {
              'Google Analytics 4': false
            }
          }
        )
    });
    rudderanalytics.load(process.env.RUDD_KEY, process.env.RUDD_PLANE);
    inject('rs', rudderanalytics)
    // context.$rs.page()
}