
import JobCard from '@/components/Jobs/JobCard.vue'
import jobsData from '@/data/jobs.json'
export default {
  components:{
    JobCard
},
  data() {
    return {
      jobs: jobsData
    
    }
  },

}
