
import {mapGetters, mapActions} from 'vuex'
import {
AisInstantSearch,
AisSearchBox,
AisAutocomplete,
AisConfigure,
AisPagination,
AisIndex,
AisStats,
AisHighlight,
AisSnippet,
AisHits,
AisRefinementList,
AisStateResults,
AisClearRefinements,
} from 'vue-instantsearch'
import 'instantsearch.css/themes/satellite-min.css'
import { debounce } from 'lodash'
import algoliasearch from 'algoliasearch/lite'
const algoliaClient = algoliasearch(
'6CXFQTPZG1',
'ee00dfb41ca6a3c3ebfb33a6876120b6'
)
let hide = true
const searchClient = {
search(requests) {
  // console.log('requests', requests)


  if (requests.every(({ params }) => !params.query)) {

    // console.log(hide)
    return Promise.resolve({
      results: requests.map(() => ({
        hits: [],
        nbHits: 0,
        nbPages: 0,
        page: 0,
        processingTimeMS: 0,
      })),
    })
  }
  hide = false
  return algoliaClient.search(requests)

},
searchForFacetValues(requests) {
  return algoliaClient.searchForFacetValues(requests)
},
facetFilters: [[`type:someProductType`]],

}


export default{
components:{
  AisInstantSearch,
  AisSearchBox,
  AisAutocomplete,
  AisConfigure,
  AisPagination,
  AisIndex,
  AisStats,
  AisHighlight,
  AisSnippet,
  AisHits,
  AisRefinementList,
  AisStateResults,
  AisClearRefinements,
},
data() {return{
  searchClient,
  index:"Pages",
  isChecked:{
    pages:true,
    blog:false,
    // products:false,
    jobs:false,
  },
}
},
computed: {
  ...mapGetters({
    activeModal : 'pages/getActiveModal',
  }),
  query() {
    return this.searchStore.query
  },
},


methods: {
  ...mapActions({
    updateActiveModal: 'pages/updateActiveModal', // map `this.add()` to `this.$store.commit('increment')`
  }),
  hideModal(){
    this.updateActiveModal(null)
  },
  refineSearch: debounce(function (refine, value) {

      if(value.length > 3) {
      // this.searchQuery = value
      refine(value)
      }
    }, 800),
  setIndex(name){
    this.index = name
  },
  toggleCheck(name){
    console.log(name)
    for (const[key, value] of Object.entries(this.isChecked)){
      console.log(key)
      if (key !== name){
        this.isChecked[key] = false
      } else if (key === name) {
        this.isChecked[key] = !this.isChecked[key]
      }
      console.log(this.isChecked)
      }
    },


  }
}

