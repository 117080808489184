
    import { mapGetters,mapActions } from 'vuex'
    import reviewData from '@/data/reviews.json'
    export default {
      data: () => ({
        reviews:reviewData
      }),
      /*
      async fetch() {
        try {
          this.reviews = await fetch('https://api.reviews.co.uk/merchant/reviews?store=tefl-org-uk&minRating=4').then(res => res.json())
        } catch (err) {
          console.log(err)
        }
      },
      */
      computed: {
        ...mapGetters({
          activeModal : 'pages/getActiveModal',
          regionClicked : 'cart/getRegionClicked',
          // category: 'pages/getCategory',
        }),
      },
      mounted() {
        this.$nextTick(function () {
          this.hideModal()

          // Interested in... Tracking call

          if ( window ) {
            // TRACKING
            if (window.location.href.includes("teach-english-abroad")) {
              console.log('Interested in TEA')
              // Track via rudderstack
              /*
              this.$rs.track("Interested in", {
                interest: 'Interested in TEA',
              })
              */
              this.$gtm.push({
                'event' : 'interested_in',
                'interest' : 'Interested in TEA'
              });
            }
            // TRACKING
            if (window.location.href.includes("teach-english-online")) {
              console.log('Interested in TEO')
              // Track via rudderstack
              /*
              this.$rs.track("Interested in", {
                interest: 'Interested in TEO',
              })
              */
              this.$gtm.push({
                'event' : 'interested_in',
                'interest' : 'Interested in TEO'
              });
            }
            // TRACKING
            if (this.$store.state.user.user_id !== ''){
              const id = this.$store.state.user.user_id
              console.log('USERID',id)
              this.$gtm.push({
                'userId' : id
              });
            }
            if (window.location.href.includes("teach-english-in-")) {
              // FIND COUNTRY
              const substring = "teach-english-in-"
              const pathArray = window.location.pathname.split('/');
              const index = pathArray.findIndex(element => {
                if (element.includes(substring)) {
                  return true;
                }
                return false
              });

              if (index !== -1) {
                  // array contains substring match
                  const str = pathArray[index];
                  const country = str.replace('teach-english-in-', '');
                  const countryFixed = country.replaceAll('-', ' ');
                  const countryFixedCapitalised = countryFixed.charAt(0).toUpperCase() + countryFixed.slice(1);
                  console.log('Interested in '+countryFixedCapitalised)
                  // Track via rudderstack
                  /*
                  this.$rs.track("Interested in", {
                    interest: 'Interested in '+countryFixedCapitalised,
                  })
                  */
                  this.$gtm.push({
                    'event' : 'interested_in',
                    'interest' : 'Interested in '+countryFixedCapitalised,
                  });
              }
            }
            // Set teflid in localstorage
            if (!localStorage.getItem('tefl_teflid')){
              const teflID = new URLSearchParams(window.location.search).get(
                "teflid"
              );
              if (teflID) {
                localStorage.setItem('tefl_teflid', teflID)

              }
            }

            // Set gclid
            this.addGclid();

          }
        })
      },
      methods: {
        ...mapActions({
          updateActiveModal: 'pages/updateActiveModal', // map `this.add()` to `this.$store.commit('increment')`
        }),
        hideModal(){
          this.updateActiveModal(null)
        },
        getExpiryRecord(value) {
          const expiryPeriod = 90 * 24 * 60 * 60 * 1000; // 90 day expiry in milliseconds
          const expiryDate = new Date().getTime() + expiryPeriod;
          return {
            value,
            expiryDate
          };
        },
        getParam(p) {
          const match = RegExp('[?&]' + p + '=([^&]*)').exec(window.location.search);
            return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
        },
        addGclid() {
          const gclidParam = this.getParam('gclid');
          const gclidFormFields = ['gclid_field']; // all possible gclid form field ids here
          let gclidRecord = null;
          let currGclidFormField;

          const gclsrcParam = this.getParam('gclsrc');
          const isGclsrcValid = !gclsrcParam || gclsrcParam.includes('aw') !== -1;

          /*
          gclidFormFields.forEach(function (field) {
            if (document.getElementById(field)) {
              currGclidFormField = document.getElementById(field);
            }
          });
          */

          if (gclidParam && isGclsrcValid) {
            gclidRecord = this.getExpiryRecord(gclidParam);
            localStorage.setItem('gclid', JSON.stringify(gclidRecord));
          }

          const gclid = gclidRecord || JSON.parse(localStorage.getItem('gclid'));
          const isGclidValid = gclid && new Date().getTime() < gclid.expiryDate;

          if (currGclidFormField && isGclidValid) {
            currGclidFormField.value = gclid.value;
          }
        }
      },
    }
