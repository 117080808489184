import {
  extend
} from "vee-validate";
import {
  required,
  alpha,
  email,
  alpha_num,
  alpha_dash,
  integer,
  min,
  max
} from "vee-validate/dist/rules";

extend("required", {
  ...required,
  message: "This field is required"
});
extend("alpha_num", {
  ...alpha_num,
  message: "Text and/or Numbers only"
});
extend("alpha", {
  ...alpha,
  message: "This field must only contain alphabetic characters"
});
extend("integer", {
  ...integer,
  message: "This field must only contain numbers"
});
extend("alpha_dash", {
  ...alpha_dash,
  message: "This field must only contain text, numbers, dashes(-) or underscores(_)"
});
extend("email", {
  ...email,
  message: "Please input a valid email"
});
extend("min", {
  ...min,
  message: "Your input is too short"
});
extend("max", {
  ...max,
  message: "Your input is too long"
});
extend("url", {
  validate: (value) => {
    const re = /^(https?:\/\/)?(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00A1-\uFFFF][a-z0-9\u00A1-\uFFFF_-]{0,62})?[a-z0-9\u00A1-\uFFFF]\.)+(?:[a-z\u00A1-\uFFFF]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/ig;
    if (re.test(value)) {
      return true
    }
    return false
  },
  message: "Please input a correct website url"
});
extend("password_chars", {

  validate: (value) => {
    const re = /^(?=.*\d)(?=.*[a-zA-Z]).{8,}$/;
    if (re.test(value)) {
      return true
    }
    return false
  },
  message: `Passwords need to include:
  <ul>
  <li>Eight or more characters</li>
  <li>At least one letter</li>
  <li>At least one number or symbol</li>
  </ul>
  `
});
extend("maths", {
  params: ["answer"],
  validate: (value, {
  answer
  }) => {

    if (answer === Number(value)) {
      return true
    }
    return false
  },
  message: "Try again"
});
extend("words", {
  params: ["maxwords"],
  validate: (value, {
    maxwords
  }) => {
    console.log(value.split(' ').length , Number(maxwords))
    if (value.split(' ').length <= Number(maxwords)) {
      return true
    }
    return false
  },
  message: "To many words"
});
