
import {html2json} from "html2json";
import wpfix from '../wpautop'

const postsQuery = `
  query getPosts(
  $where: RootQueryToPostConnectionWhereArgs
  $after: String
  $before: String
  $first: Int
  $last: Int){
posts(
  where: $where
      after: $after
      before: $before
      first: $first
      last: $last) {
              pageInfo {
                hasNextPage
                startCursor
                endCursor
                hasPreviousPage
              }
              edges {
                node {
                  id
                  uri
                  slug
                  title
                  content(format: RAW)
                  excerpt(format: RENDERED)
                  postId
                  template {
                    templateName
                  }
                  thumbnail
                  categories {
                    nodes {
                      name
                      uri
                    }
                  }

                }
              }
            }
          }
`

const postQuery = `
 fragment postData on Post {
    id
    slug
    title
    postId
    seo {
      fullHead
      metaRobotsNoindex
      metaRobotsNofollow
      }
    content(format: RAW)
    uri
    status
    excerpt(format: RENDERED)
    template{
        templateName
    }
    thumbnail
    categories {
      nodes {
        name
        uri
      }
    }
    commentCount
    commentStatus
  }
  query GetContentNode($id: ID!) {
    contentNode(id: $id, idType: URI) {

      ... on Post {
        ...postData
      }
    }
  }
`
const paginationQuery=`
query MyQuery($offset: Int) {
  posts(where: {offsetPagination: {size: 30, offset: $offset}}) {
    edges {
      node {
        title
        uri
        seo {
          fullHead
          metaRobotsNoindex
          metaRobotsNofollow
          }
          excerpt(format: RENDERED)
          thumbnail
          categories {
            nodes {
              name
              uri
            }
          }
      }
    }
  }
}`
const categoryQuery=`
query MyQuery($slug: String) {
  categories(where: {slug: [$slug]}) {
    edges {
      node {
        uri
        name
        posts {
          edges {
            node {
              title
              uri
              excerpt
              thumbnail
              seo {
                fullHead
                metaRobotsNoindex
                metaRobotsNofollow
              }
              categories {
                nodes {
                  name
                  uri
                }
              }
            }
          }
          pageInfo {
            offsetPagination {
              hasMore
              total
              hasPrevious
            }
          }
        }
      }
    }
  }
}`


export function state() {
  return {
    nodes: [],
    pageInfo: {
      endCursor: '',
      hasNextPage: false,
      hasPreviousPage: false,
      startCursor: '',
    },
    post: {
      id: '',
      slug: '',
      title: '',
      content: '',
      excerpt: '',
      uri: '',
      status: '',
      template:'',
      thumbnail:''
    },


  }
}

export const mutations = {
  setPosts(state, posts) {
    state.nodes = posts
  },
  setPost(state, post) {
    state.post = post
  },
  setPageInfo(state, pageInfo) {
    state.pageInfo = pageInfo
  },


}

export const actions = {
   async getPostbyPage({commit},variables){

    const result = await this.$wp(paginationQuery,variables)
    const {
      edges,

    } = result.posts
    return edges

  },
  async getPostbyCategory({commit},variables){

    const result = await this.$wp(categoryQuery,variables)

    const {
      edges,

    } = result.categories.edges[0].node.posts
    return edges

  },
  async getPosts({
      commit
    },
    variables
  ) {
    const result = await this.$wp(postsQuery,variables)

    const {
      edges,
      pageInfo
    } = result.posts

    commit('setPosts', edges)
    commit('setPageInfo', pageInfo)
  },
  async getPost({
      commit
    },
    slug
  ) {

    const result = await this.$wp(postQuery,{
      id: slug,
    })

    const post = result?.contentNode
    const cleanHtml=post.seo.fullHead.replace(/\n\s*/ig, "").replace(/<!--(.*?)-->/ig,"")
    post.seo.fullHead = html2json(cleanHtml)
   post.content = await wpfix(post.content)
    commit('setPost', post)
    return post
  },


}
