
import { createWidgetMixin, AisHighlight, AisStats } from 'vue-instantsearch';
import { connectInfiniteHits } from 'instantsearch.js/es/connectors';
export default {
  components:{
    AisHighlight,
    AisStats,
  },
  props:['indexName'],
  mixins: [createWidgetMixin({ connector: connectInfiniteHits })],
  methods: {
    visibilityChanged(isVisible) {
      if (isVisible && !this.state.isLastPage) {
        this.state.showMore();
      }
    },
  },
};
