import { render, staticRenderFns } from "./New Layout.vue?vue&type=template&id=9e6d1cf8&scoped=true&"
var script = {}
import style0 from "./New Layout.vue?vue&type=style&index=0&id=9e6d1cf8&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9e6d1cf8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UIHeader: require('/opt/build/repo/components/UI/Header.vue').default,UIMenu: require('/opt/build/repo/components/UI/Menu.vue').default,UIFooter: require('/opt/build/repo/components/UI/Footer.vue').default})
