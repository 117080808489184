
export default {
  layout: 'error',

  props: {
    error: {
      type: Object,
      default: ()=>{}
      }
      }, // you can set a custom layout for the error page
}
