import { render, staticRenderFns } from "./TEFL Org Header.vue?vue&type=template&id=494857ee&scoped=true&comments=true&"
import script from "./TEFL Org Header.vue?vue&type=script&lang=js&"
export * from "./TEFL Org Header.vue?vue&type=script&lang=js&"
import style0 from "./TEFL Org Header.vue?vue&type=style&index=0&id=494857ee&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "494857ee",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UIHeader: require('/opt/build/repo/components/UI/Header.vue').default,UIMenu: require('/opt/build/repo/components/UI/Menu.vue').default,CartBannerTop: require('/opt/build/repo/components/Cart/BannerTop.vue').default,UIFooter: require('/opt/build/repo/components/UI/Footer.vue').default})
