
import TwoColumnsInternships from './TwoColumnsInternships.vue'

import Homepage from '~/layouts/Homepage.vue'
import TEFLOrgHeader from '~/layouts/TEFL Org Header.vue'
import TEFLOrgHeaderSEO from '~/layouts/TEFL Org Header SEO.vue'
import TEFLOrgMedia from '~/layouts/TEFL Org Media.vue'
import FixedWidthPage from '~/layouts/Fixed Width Page.vue'
import FixedWidthHero from '~/layouts/Fixed Width Hero.vue'
import FixedWidthHeroNew from '~/layouts/Fixed Width Hero New.vue'
import TEFLOrgCountryGuide from '~/layouts/TEFL Org Country Guide.vue'
import TEFLOrgJobGuide from '~/layouts/TEFL Org Job Guide.vue'
import TEFLOrgHeaderGreyBG from  '~/layouts/TEFL Org Header GreyBG.vue'
import TEFLJobsCentrePagination from  '~/layouts/TEFL Jobs Centre Pagination.vue'
import FixedWidthHeroInContent from '~/layouts/Fixed Width Hero in Content.vue'
import NewLayout from  '~/layouts/New Layout.vue'
import Squeeze from  '~/layouts/Squeeze.vue'
import NewOnlineCoursePage from  '~/layouts/New Online Course Page.vue'
import NewOnlineCoursePageUK from  '~/layouts/New Online Course Page UK.vue'
import NewCoursePageTemplate from  '~/layouts/New Course Page Template.vue'
export default {
  components: {
   
    Homepage,
    TEFLOrgHeader,
    TEFLOrgHeaderSEO,
    TEFLOrgMedia,
    FixedWidthPage,
    TEFLOrgCountryGuide,
    FixedWidthHero,
    FixedWidthHeroNew,
    TEFLOrgJobGuide,
    TEFLOrgHeaderGreyBG,
    TEFLJobsCentrePagination,
    NewLayout,
    FixedWidthHeroInContent,
    NewOnlineCoursePage,
    NewOnlineCoursePageUK,
    NewCoursePageTemplate,
    TwoColumnsInternships,
    Squeeze
  },
  data() {
    return {
      page: {},
    }
  },
 

  computed: {
    getTemplate() {
      const defaultTemplate = 'TEFLOrgHeader'
      if (this.$store.state.pages.page.template){
        if(this.$store.state.pages.page.template.templateName === 'Default'){return defaultTemplate}
        // Check if we have the template registered, if not we can default to TEFLOrgHeader
        const componentExists = this.$store.state.pages.page.template.templateName
          .split(' ')
          .join('') in this.$options.components
        if(componentExists){
          return this.$store.state.pages.page.template.templateName
          .split(' ')
          .join('')
        } else {
          return defaultTemplate
        }
      }
      else return defaultTemplate
    },
  },
mounted() {
    this.$nextTick(() => {
      this.$nuxt.$loading.start()
      setTimeout(() => this.$nuxt.$loading.finish(), 500);
      const init = document.getElementById('nuxt-speedkit-button-init-app')
      if(init)
      init.click()
    console.log('forced init')
    })
}
}
