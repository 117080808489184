export default async ({app, store, req, query, isDev, cookies}) => {

  console.log('Geolocate plugin init: ',process.static, process.server)

 //  if (process.server && !process.static) {

    const g = {
      "GBP": 'TEFLORG',
      'USD': "TEFLUS",
      'EUR': "IRELAND",
      'CAD': "CANADA",
      'AUD': "AUSTRALIA",
      'ZAR': "SOUTHAFRICA"
    }

    const defaultSaved = store.state.geolocation.defaultSaved
    console.log('Store GEO: ',store.state.geolocation.detectedTimezone)
    console.log('GEO Saved: ', defaultSaved)

    // Abort if we already have a country
    if (defaultSaved) {
      console.log('Already got TZ: ', defaultSaved)
      return
    }

    const guessTz = Intl.DateTimeFormat().resolvedOptions().timeZone
    const priceLabel = g[app.$cookies.get('tefl_geolocation_currency')]
    console.log('GETTING TZ... ', guessTz)
    console.log('GETTING Label... ', priceLabel)

    await store.commit("geolocation/SET_GEOLOCATION", {
      detectedTimezone: app.$cookies.get('tefl_geolocation_timezone'),
      detectedCity: app.$cookies.get('tefl_geolocation_city'),
      detectedCountry: app.$cookies.get('tefl_geolocation_country'),
      detectedRegion: app.$cookies.get('tefl_geolocation_region'),
      detectedCurrency: app.$cookies.get('tefl_geolocation_currency'),
    })
    await store.commit("cart/setPriceLabel",priceLabel)
    await store.commit("geolocation/SET_DEFAULT",true)

  // }

}