const getDefaultState = () => {
  return {
    jobSearchUrl: '',
    paginationLinks: '',
  }
}

export const state = () => getDefaultState();
export const mutations = {
  setJobSearchUrl(state, url) {
    state.jobSearchUrl = url
  },
  setPaginationLinks(state, links) {
    state.paginationLinks = links
  },
}

export const getters = {
  jobSearchUrl: (state) => {
    return state.jobSearchUrl
  },
  paginationLinks: (state) => {
    return state.paginationLinks
  },
}

export const actions = {
  updateJobSearchUrl({commit}, url) {
    commit('setJobSearchUrl', url)
  },
  updatePaginationLinks({commit}, links) {
    commit('setPaginationLinks', links)
  },


}
