
import { mapGetters } from 'vuex'
import menuData from '../../data/menu.json';
// import menuUSData from '../../data/menuUS.json';
export default {
 data() {
    return {
      countDown: 60
    };
  },
  computed:{
    ...mapGetters({
      // region: 'user/region',
      detectedRegion: 'geolocation/region',
      userRegion: 'user/region',
      currentLabel: 'cart/getPriceLabel',
      detectedCountry: 'geolocation/detectedCountry',
    }),
    activeFlag(){
      const fl = this.$nuxt.$options.filters.flagFromLabel(this.currentLabel,this.detectedCountry)
      console.log('MENU FLAG',fl)
      return fl
    },
    region(){
      console.log('MENU REGIONS',this.userRegion, this.detectedRegion)
      return this.userRegion === 'default' ? this.detectedRegion : this.userRegion
    },
   menu(){
  //  if(this.region==='en')
    return menuData
  //  else
  //  return menuUSData
    }
  },
  watch: {
    countDown: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.countDown--;
          }, 1000);
        }
      },
      immediate: true // This ensures the watcher is triggered upon creation
    }
  }
}
