
import { mapGetters, mapActions } from 'vuex'

export default {
  props:['phone'],
  data() {
    return {
      openingHours: '08:00',
      closingHours: '00:00',
      openingHours2: '08:00',
      closingHours2: '22:00',
      tzz:''
      // phoneNumber: '0330 808 9138',
    }
  },
  computed: {
    ...mapGetters({
      timeZone: 'geolocation/detectedTimezone',
      getHours: 'geolocation/getHours',
      detectedRegion: 'geolocation/region',
      userRegion: 'user/region',
      regionClicked : 'cart/getRegionClicked'
    }),
    region(){
      if(process.client){
        return this.userRegion === 'default' ? this.detectedRegion : this.userRegion
      }
      return this.userRegion
    },
    times(){
      if(process.client){
        const bst=this.$nuxt.$options.filters.isBST(new Date())
        // const zone=Intl.DateTimeFormat().resolvedOptions().timeZone;
        const zone = this.timeZone
        // console.log('TIMEZONE: ', this.timeZone)
        const now=this.$moment().format('YYYY-MM-DD');

        let otime=this.$moment(now + ' ' + this.openingHours + 'Z');
        if(bst){otime=otime.subtract(1,'hours')}

        let ctime=this.$moment(now + ' ' + this.closingHours + 'Z');
        if(bst){ctime=ctime.subtract(1,'hours')}

        const starttime=this.$moment(otime).tz(zone).format('ha ');
        const closingtime=this.$moment(ctime).tz(zone).format('ha (z)');

        return starttime+' - '+closingtime
      }
      // Need default here to prevent error
      return this.openingHours+' - '+this.closingHours+ '(GMT)'
    },
    times2(){
      if(process.client){
        const bst=this.$nuxt.$options.filters.isBST(new Date())
        // const zone=Intl.DateTimeFormat().resolvedOptions().timeZone;
        const zone = this.timeZone
        // console.log('TIMEZONE: ', this.timeZone)
        const now=this.$moment().format('YYYY-MM-DD');

        let otime=this.$moment(now + ' ' + this.openingHours2 + 'Z');
        if(bst){otime=otime.subtract(1,'hours')}

        let ctime=this.$moment(now + ' ' + this.closingHours2 + 'Z');
        if(bst){ctime=ctime.subtract(1,'hours')}

        const starttime=this.$moment(otime).tz(zone).format('ha ');
        const closingtime=this.$moment(ctime).tz(zone).format('ha (z)');

        return starttime+' - '+closingtime
      }
      // Need default here to prevent error
      return this.openingHours+' - '+this.closingHours+ '(GMT)'
    },
    xmas(){
      if(process.client){
        const now = this.$moment().format('YYYY-MM-DD');
        if(now > '2023-12-24' && now < '2023-12-27'){
          return true
        }
      }
      return false
    },
    newyear(){
      if(process.client){
        const now = this.$moment().format('YYYY-MM-DD');
        if(now > '2023-12-31' && now < '2024-01-02'){
          return true
        }
      }
      return false
    },
    phoneNumber(){
      console.log('PHONE',this.regionClicked)
      // if(this.$props.phone==='US'){
        // return '<a href="tel:888 897 2570">888.897.2570</a>'
      // UK
      if(this.regionClicked === 'TEFLORG') {
        return '<a href="tel:0330 808 9138">0330 808 9138</a>'
      } 
      // IRELAND
      else if(this.regionClicked === 'IRELAND') {
        return '<a href="tel:+353 1 270 7964">+353 1 270 7964</a>'
      // US
      } else if(this.regionClicked === 'TEFLUS'){
        return '<a href="tel:888 897 2570">888.897.2570</a>'
      // EU
      } else if(this.regionClicked === 'EU'){
        return '<a href="tel:+44 330 808 9138">+44 330 808 9138</a>'
      // ROW
      } else if(this.regionClicked === 'ROW'){
        return '<a href="tel:+1 888 897 2570">+1 888.897.2570</a>'
      // EVERYWHERE ELSE
      } else if(
        this.regionClicked !== 'TEFLUS' 
        && this.regionClicked !== 'TEFLORG' 
        && this.regionClicked !== 'IRELAND'
        && this.regionClicked !== 'EU'
        && this.regionClicked !== 'ROW') {
        return '<a href="tel:+1 888 897 2570">+1 888.897.2570</a>'
      }
      // IN CASE OF ERROR DEFAULT TO UK
      return '<a href="tel:+44 330 808 9138">+44 330 808 9138</a>'
    },
  },

  methods: {
    ...mapActions({
      setHours: 'geolocation/setHours',
    }),
  },
}
