
import moment from 'moment'
import { mapGetters } from 'vuex'
import bannerData from '@/data/banner.json'
export default {
  data: () => ({
    time: {Days:"0",Hours:"0",Minutes:"0",Seconds:"0"},
    zeroTime: {Days:"00",Hours:"00",Minutes:"00",Seconds:"00"},
    i: 0,
    ended:false
    // trackers: ['Days', 'Hours','Minutes','Seconds'] // 'Random', 
  }),

  async fetch() {
    this.banner = await bannerData
    
  },

  computed: {
    ...mapGetters({
      // region: 'user/region',
      detectedRegion: 'geolocation/region',
      userRegion: 'user/region',
    }),
    region(){
      return this.userRegion === 'default' ? this.detectedRegion : this.userRegion
    }
  },
  
  watch: {
    'date'(newVal){
      this.setCountdown(newVal);
    }
  },

  mounted() {
    const todayDate = moment()
    let dateData = bannerData.date_text_bf
    let timeData = ' 23:59:59'
    let saleDate = moment(dateData).add(1,'days')
    if (this.region === 'en-us' ){
      dateData = bannerData.date_text_us_bf
      timeData = ' 09:00:00'
      saleDate = moment(dateData).add(1,'days')
    }
    if(todayDate.isAfter(saleDate)) {
      this.ended=true
    } else {
      this.setCountdown(dateData+timeData);this.update();
    }

  },
  
  methods: {
    
    setCountdown(date){
      // "banner.date_text+' 23:59:59'"
      if ( date ) {
        this.countdown = moment(date, 'YYYY-MM-DD HH:mm:ss');
      } else {
        this.countdown = moment() 
      }
    },
    
    update() { 
      const time={Days:"00",Hours:"00",Minutes:"00",Seconds:"00"}
      this.frame = window.requestAnimationFrame(this.update.bind(this));
      if ( this.i++ % 10 ) { return; }
      let t = moment(new Date());
      // Calculation adapted from https://www.sitepoint.com/build-javascript-countdown-timer-no-dependencies/
      if ( this.countdown ) { 
        
        t = this.countdown.diff(t);

        time.Days = Math.floor(t / (1000 * 60 * 60 * 24));
        time.Hours = Math.floor((t / (1000 * 60 * 60)) % 24);
        time.Minutes = Math.floor((t / 1000 / 60) % 60);
        time.Seconds = Math.floor((t / 1000) % 60);
      } else {
        time.Days = undefined;
        time.Hours = t.hours() % 13;
        time.Minutes = t.minutes();
        time.Seconds = t.seconds();
      }
      
   //   time.Total = t;
      
      // this.$emit('time',time);
     // console.log(this.time)
     this.time=time
      return this.time;
    }
  }
}
