
import { mapGetters } from 'vuex'
export default {
  data() {
    return{
    }
  },
  computed: {
    ...mapGetters({
      // region: 'user/region',
      detectedRegion: 'geolocation/region',
      userRegion: 'user/region',
      currentLabel: 'cart/getPriceLabel',
      detectedCountry: 'geolocation/detectedCountry',
    }),
    activeFlag(){
      const fl = this.$nuxt.$options.filters.flagFromLabel(this.currentLabel,this.detectedCountry)
      console.log('HEADER FLAG',fl)
      return fl
    },
    region(){
      console.log('MENU REGIONS',this.userRegion, this.detectedRegion)
      return this.userRegion === 'default' ? this.detectedRegion : this.userRegion
    }
  },
}
